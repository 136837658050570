.impersonation-banner {
  $banner-bg: rgba(#ffffff, 1.0);
  $banner-text: #f0261d;
  $banner-border: #f0261d;
  $banner-shadow: rgba(0, 0, 0, 0.2);

  position: fixed;
  left: calc(50% - 150px);
  top: 1rem;
  z-index: 1050;
  background-color: $banner-bg;
  border: 2px solid $banner-border;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 2px 8px $banner-shadow;
  width: 300px;
  backdrop-filter: blur(8px);

  .banner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    font-size: 0.875rem;

    .user-info {
      color: $banner-text;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .exit-button {
      display: inline-flex;
      align-items: center;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      color: $banner-text;
      background-color: transparent;
      border: 1px solid $banner-text;
      border-radius: 0.25rem;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
      white-space: nowrap;
      flex-shrink: 0;

      &:hover {
        background-color: rgba($banner-text, 0.1);
      }

      .close-icon {
        font-size: 1rem;
        line-height: 1;
        margin-right: 0.25rem;
      }
    }
  }
}