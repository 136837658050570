.how-it-works {
    margin-top: 21px;

    @include media-breakpoint-between(md, lg){
        margin-top: 46px;

        .card-group__feature-deck {
            margin-top: 115px;
        }
    }

    @include media-breakpoint-up(lg){
        margin-top: 80px;

        .card-group__feature-deck {
            margin-top: 150px;
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: -21px;
    }

    &__header {
        @include make-horizontal-sep(after,  2px, 62px, 100px){
            margin-top: 20px;

            @include media-breakpoint-between(md, lg) {
                width: 82px;
                margin-top: 25px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: 56px;
            }
        }
    }

    &__title {
        font-size: 14px !important;
        font-weight: 800;

        @include media-breakpoint-up(lg){
            font-size: 18px !important;
        }
    }

    &__paragraph {
        line-height: 20px;

        @include media-breakpoint-down(lg){
            max-width: 250px;
            margin: auto;
        }
        @include media-breakpoint-between(md, lg){
            font-size: 12px !important;
        }

        @include media-breakpoint-up(lg){
            line-height: 24px;
        }
    }
}
