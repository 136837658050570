.cart-ticket {

    @include media-breakpoint-up(md) {
        padding-top: 17px;
        position: relative;
    }

    @include media-breakpoint-between(md, lg) {
        &__edit-col {
            padding-left: 0;
            margin-left: -10px;
        }
    }

    @include media-breakpoint-up(lg) {
        .col-lg-4 {
            padding-left: 35px;
        }

        .col-2 {
            padding-right: 30px;
        }
    }

    &__product-image {
        width: 80px;
        height: 80px;

        @include media-breakpoint-up(lg) {
            width: 110px;
            height: 110px;
        }
    }

    &__description,
    &__details {
        font-weight: 500;
        font-size: 10px;
        line-height: normal;

        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    &__details {
        white-space: pre-line;
        margin-top: 16px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    &__description {
        margin-bottom: 0;
        margin-top: 8px;

        @include media-breakpoint-up(lg) {
            margin-top: 15px;
        }
    }

    &__contents {
        padding: 15px 10px 5px 10px;

        &__info {
            display: flex;

            &__image {
                height: 80px;
                width: 80px;
                margin-right: 15px;
                margin-left: 10px;
                margin-top: 10px;

                @include media-breakpoint-up(md) {
                    width: unset;
                    flex: 0 40%;
                }
            }

            &__match {
                line-height: normal;
                letter-spacing: 0.01em;
                margin: 0;
                color: $title;

                span {
                    font-weight: 500;
                    //color: map_get($theme-colors, "paragraph-color");

                    &:last-child {
                        font-size: 12px;

                        @include media-breakpoint-up(md) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        &__member-card {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            cursor: pointer;
            padding-bottom: 7px;

            @include media-breakpoint-up(md) {
                align-items: center;
                margin-bottom: 17px;
                padding: 0;
                border: none;
            }

            @include media-breakpoint-up(lg) {
                flex: auto;
                padding-right: 15px;
            }

            @include media-breakpoint-up(xl) {
                align-items: baseline;
            }

            &[aria-expanded="true"] {
                //.cart-ticket__contents__member-card__modal-btn .icon{
                //    @extend .icon--small-arrow--up;
                //}

                .cart-ticket__contents__member-card__modal-btn.active {
                    width: 9px;
                    height: 9px;
                }

                .cart-ticket__contents__member-card__modal-btn.active .icon{
                    @extend .icon--close;
                    width: 9px;
                    height: 9px;
                    background-color: $error-text;
                    opacity: 1.0;
                }
            }

            &[aria-expanded="false"] {
                .cart-ticket__contents__member-card__modal-btn .icon{
                    @extend .icon--small-arrow--up;
                }
            }

            &__associate-label {
                margin: 0;
                line-height:  16px;
                background: $clickable-links;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @include media-breakpoint-up(md) {
                    line-height: 19px;
                }
            }

            &__info {

                max-width: 190px;

                @include media-breakpoint-up(xl) {
                    max-width: unset;
                }

                &__owner {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0;
                    color: $main-text;

                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    &__mobile {
                        line-height: 17px;
                    }

                    @include media-breakpoint-up(md) {
                        span {
                            font-weight: 500;
                            color: $body-color;
                        }
                    }

                }

                &__team {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 24px;
                    margin: 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    &__mobile {
                        line-height: 17px;
                    }
                }
            }

            &__modal-btn {
                width: 9px;
                height: 5px;
                i {
                    width: 5px;
                    height: 9px;
                }
            }
        }

        &__edit {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &--no-discount {
                margin-bottom: 0 !important;
            }

            @include media-breakpoint-down(sm) {
                margin-top: 17px;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 22px;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 0;
                margin-bottom: 19px;
                flex: auto;
                align-items: center;
            }

            &__quantity {
                width: 80px;
                height: 40px;
                margin-left: 10px;
                margin-right: 15px;
            }

            &__prices {
                display: flex;
                align-items: center;

                @include media-breakpoint-up(md) {
                    align-items: flex-end;
                    flex-direction: column;
                }

                &__effective {
                    margin: 0;
                    font-weight: 600;
                    line-height: 24px;
                    font-size: 14px;
                    color: $title;
                }

                &__box-office {
                    user-select: none;
                    margin: 0 0 0 8px;
                    text-decoration-line: line-through;
                }
            }

            &__trash-can {
                cursor: pointer;
                margin: 0;
                width: 14px;
                height: 14px;
            }
        }

        &__error {
            margin-top: 15px;

            @include media-breakpoint-up(lg) {
                margin-top: 20px;
                padding-left: 35px;
            }
        }
    }

    &__accordion {
        position: relative;
        max-height: 0;
        height: auto;
        overflow: hidden;
        transition: max-height 0.5s ease, opacity 0.5s ease;
        opacity: 0;

        @include media-breakpoint-up(md) {
            margin-top: 16px;
        }

        &.show {
            max-height: 400px;
            opacity: 1.0;
        }
    }

    &__error-box{
        display: flex;
        gap: .25rem;
        align-items: center;
        color: $error-text;
    }
}


.card-ticket-new {

    margin-top: 15px;
    padding-top: 5px;
    border-bottom: 1px solid $lines-and-box;

    &__container-price {
        max-width: 90%;
    }

    &__price {
        display: flex;
        align-items: center;

        &__mobile {
            margin-right: 25px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 10px;
        text-decoration-line: line-through;
        display: flex;
        align-items: center;
        justify-content: end;
        line-height: 0;

        &__mobile {
            display: unset;
        }
    }

    &__details {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
    }

    &__text-gradient {
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        background: $button;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__no-line-height {
        line-height: normal !important;
    }

    &__btn-expand {
        display: flex;
        align-items: center;
        &__mobile {
            margin-right: 25px;

            &__icon {
                margin: 0;
                cursor: pointer;
                width: 12px;
                height: 12px;
            }
        }

    }

}
