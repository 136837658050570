.tooltip-container {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: help;
  position: relative;
  vertical-align: middle;
}

.tooltip-icon {
  transition: all 0.2s ease;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}

.tooltip-content {
  position: absolute;
  left: 24px;
  top: -8px;
  background-color: $main-text;
  color: $background;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.3;
  min-width: 160px;
  max-width: 250px;
  white-space: normal;
  word-wrap: break-word;
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-transform: none;
  text-align: center;
}

.tooltip-arrow {
  position: absolute;
  left: -5px;
  top: 10px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid $main-text;
}

.tooltip-text {
  width: 100%;
  text-align: center;
}
