.modal {
		&-header {
				&--stadium-map {
					padding: 12px 0 10px !important;
				}
		}

		&__close {
				&--stadium-map {
						position: absolute;
						right: 22px;
						line-height: 42px;
				}
		}

		&__paragraph {
				&--stadium-map {
						margin: 28px 0 38px;

						@include media-breakpoint-up(md) {
								margin: 0 0 38px;
						}
				}
		}
}