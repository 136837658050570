.member-cards-carousel {
    //background: #FAFAFA;
    overflow: hidden;
    white-space: nowrap;

    &__search-box {

        z-index: 2;

        @include media-breakpoint-up(md) {
            border-radius: 2px !important;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 !important;
            border-radius: 0;
            border-top: none !important;
            border-right: none !important;
            border-left: none !important;
            border-bottom-color: $main-text;
        }

        &::placeholder {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            font-style: normal;
            letter-spacing: 0.01em;
            color: $main-text;

            @include media-breakpoint-up(md) {
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
            }
        }

        &__button {
            position: absolute;
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            padding: 0;
            right: 1.75px;
            top: 50%;
            background: $main-text;
            z-index: 4;
            opacity: 1;
            transition: opacity .3s;

            @include media-breakpoint-up(md) {
                background: none;
                right: 14px;
                height: 38px;
                top: 30px;
            }

            @include media-breakpoint-up(lg) {
                height: 38px;
                top: 24px;
            }
        }

        &__container {
            padding: 18px 1.75px 0;

            @include media-breakpoint-up(md) {
                width: 380px;
                padding: 30px 0 0 15px;
            }

            @include media-breakpoint-up(lg) {
                padding: 24px 0 2px 30px;
            }
        }
    }

    &__container {
        display: inline-flex;
        padding: 32px 55px;

        @include media-breakpoint-down(md) {
            padding: 20px 25px;

        }
        @include media-breakpoint-only(md) {
            padding: 20px 13px;
        }

        @include media-breakpoint-up(lg) {
            padding: 30px;
        }
    }

    &__add_card-btn {
        width: 110px;
        height: 90px;
        min-height: 96px;
        border-radius: 8px;
        margin-right: 25px;
        position: relative;
        padding-left: 5px;
        padding-right: 5px;

        .btn__text-gradient {
            white-space: normal;
        }

        &::after {
            border-radius: 7px;
        }
    }

    &__next-btn {
        height: 96px;
        background: radial-gradient(100% 115.47% at 0% 50%, #FAFAFA 26.4%, rgba(250, 250, 250, 0) 59.39%);
        cursor: pointer;
        position: absolute;
        top: 90px;
        right: 12px;
        width: 35px;
        transform: rotate(-180deg);
        transition: opacity 0.5s ease-in-out;
        @include media-breakpoint-between(sm, md) {
            top: 80px;
        }
        i {
            position: center;
            align-items: center;
            transform: rotate(90deg);
            background: $main-text !important;
        }

        &--disabled {
            transition: opacity 0.5s ease-in-out;
            opacity: 0.0;
            cursor: default;
        }
    }

    &__prev-btn {
        height: 96px;
        background: radial-gradient(100% 115.47% at 0% 50%, #FAFAFA 26.4%, rgba(250, 250, 250, 0) 59.39%);
        cursor: pointer;
        position: absolute;
        top: 90px;
        left: 12px;
        width: 35px;
        transition: opacity 0.5s ease-in-out;
        @include media-breakpoint-between(sm, md) {
            top: 80px;
        }
        i {
            position: center;
            align-items: center;
            transform: rotate(90deg);
            background: $main-text !important;
        }

        &--disabled {
            transition: opacity 0.5s ease-in-out;
            opacity: 0.0;
            cursor: default;
        }
    }

    &__error-box{
        &__container{
            padding-left: 1.8rem;
            display: flex;
            gap: .25rem;
            align-items: center;
            color: $error-text;
        }
    }
}
